import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import JumboPhoto from "../../../components/jumbophoto"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

export default () => <Layout>
	<Helmet title="Avod Foreclosure | Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
		<Row>
			<Col>
				<h1 className="text-primary">Avoid Foreclosure</h1>
				<hr />
				<p>Have you worked for years to keep up with the mortgage payments on your home? If you are in danger of losing your house because of mounting personal debt, we can help you come up with a plan that will help you keep your assets. At the Birmingham, Alabama, offices of Gina H. McDonald &amp; Associates, we understand how hard it can be to fight off debt and pay your mortgage. We can help you stop foreclosure.</p>

				<p className="lead">If you have questions about debt relief, please contact Gina H. McDonald &amp; Associates at <a href="tel://12059823325">(205) 982-3325</a>.</p>
				<p>Even if you have been making timely payments for years, high interest rates might be making it difficult for you to keep up with your house payments. By filing for bankruptcy, you can immediately stop foreclosure on your home. We understand how difficult this process can be, but if you have reached the point where you need help to keep you home, we will provide you with the compassionate personal service and aggressive action you need to keep your assets.</p>
				<p>If you have received a foreclosure notice from your creditors, it is not too late to take action. By filing for bankruptcy, you get an automatic stay, which will stop harassing phone calls from creditors, stop foreclosure, help you save your car, and stop all garnishments against you. If you fear that your inability to keep up with mortgage payments could eventually lead to the foreclosure of your house, a bankruptcy lawyer can help you understand you options.</p>

				<h2>Contact Us</h2>
				<p>If you want to avoid foreclosure, <Link to="/practice-areas/bankruptcy-law/can-i-keep-my-car">save your car</Link>, and pay off your debt, it may be time to consider <Link to="/practice-areas/bankruptcy-law/chapter-7">Chapter 7</Link> or <Link to="/practice-areas/bankruptcy-law/chapter-13">Chapter 13</Link> personal bankruptcy. At the Birmingham, Alabama, offices of Gina H. McDonald &amp; Associates, we can help you keep your home and get the debt relief you deserve. Contact us today for an initial consultation.</p>
				<p className="lead">We are a debt relief agency. We help people file for debt relief under the Bankruptcy Code.</p>
				<p>The following language is required pursuant to Rule 7.2, Alabama Rules of Professional Conduct. No representation is made that the quality of legal services to be performed is greater than the quality of legal services performed by other lawyers. This web site is designed for general information only. The information presented at this site should not be construed to be formal legal advice nor the formation of a lawyer/client relationship.</p>
			</Col>
		</Row>
	</Container>
</Layout>